import React from 'react';
import { connect } from 'react-redux';
import * as Redux from '../../../../components/redux';

class CIframe extends React.Component {
  componentDidMount() {
    window.addEventListener('message', this.handleIframeTask);
  }

  handleIframeTask = (e) => {
    if (e.origin !== 'https://res.webapi.network') {
      return;
    }
    if (e.data?.name == "balanceChanged") {
      Redux.updateUserCredit({userCredit: e.data.balanceAfter.toFixed(2)})
    }
  };

  render() {
    const { gameUrl } = this.props;

    return (
      <div className="iframe-cont">
        <button onClick={() => {   this.props.openticket(); }} className="iframe-check">
          <i className="fas fa-check-square" />
        </button>
        <button onClick={() => { this.props.onClose() }} className="iframe-close">
          <i className="fas fa-times" />
        </button>
        <iframe
          title="Game Content"
          style={{ width: "100%", height: "100%" }}
          src={gameUrl}>
       
       
        </iframe>
      </div>
    );
  }
}

const msp = (state) => {
  return {
    config: state.config
  }
}

export default connect(msp)(CIframe);