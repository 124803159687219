import React from "react";
import { TextInput } from "react-native";
import { connect } from "react-redux";
import {
  Redux,
  WebClient,
  constants,
  Overlay,
  getUserData,
  tF,
} from "../../../components";
import { FormattedMessage, injectIntl } from "react-intl";
import assets from "../../../components/utils/assets";
import { IntlContext } from "../../../components/intl-providers/main-intl-provider";
import Swal from "sweetalert2/src/sweetalert2.js";
import { LangChanger } from "../../../components/utils/webclient";
import MainHeader from "./components/header";

import Coupon from "./coupon";
import TicketCheck from "./components/ticket-check";
import AdminLogin from "./components/admin-login";

import Matches from "./matches";
import LiveMatches from "./live-matches";
import LiveBingo from "./live-bingo";
import LiveCasino from "./live-casino";
import SlotGames from "./slot-games";
import VirtualGames from "./virtual-games";
import ManagerPage from "./manager/index";
import IdlePage from "./idle";
import SocketUtil from "../../../components/socket";

import TicketDetail from "./manager/ticket-detail";

let listener_initialized = false;
let barcode_value = [];
let _ss = false;

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.wc = new WebClient();
    this.socket = undefined;
    this.state = {
      activeScreen: "LiveMatches",
      isLanguageOverlayOpen: false,
      showCoupon: false,
      showTicketCheck: false,
      showPasswordPanel: false,
      barcodeValue: "",
    };
  }
  setState(state, callback) {
    super.setState(state, () => {
      getUserData();
      if (callback) callback();
    });
  }
  componentDidMount() {
    if (!this.props.initialized) {
      /* */
      this.SetConnectionListener();
      Redux.setInitialized(true);
    }

    this._initBarcodeListener();
    this._socket();
  }

  SetConnectionListener() {
    window.addEventListener("online", this.onlineStatusChange);
    window.addEventListener("offline", this.onlineStatusChange);
  }

  onlineStatusChange() {
    if (navigator.onLine) {
      if (this.socket) this.socket.reset();
    }
  }

  _socket() {
    _ss = true;
    this.socket = new SocketUtil();
    this.socket.init();
  }

  _initBarcodeListener() {
    let barcode = "";
    let interval;
    document.addEventListener("keydown", (event) => {
      if (interval) clearInterval(interval);
      if (event.code == "Enter") {
        if (barcode.length > 0) {
          this._handleBarcode(
            barcode.replace(/\*/g, "").replace(/\(/g, "").replace(/\)/g, "")
          );
          barcode = "";
          return;
        }
      }

      if (
        event.code != "Shift" &&
        event.code != "ShiftLeft" &&
        event.code != "ShiftRight"
      ) {
        barcode += event.key;
      }

      interval = setInterval(() => {
        barcode = "";
      }, 20);
    });
  }

  _handleBarcode(b) {
    this.setState({ showTicketCheck: true, barcodeValue: b });
  }

  openCoupon() {
    this.setState({ showCoupon: true });
  }

  closeCoupon() {
    this.setState({ showCoupon: false });
  }

  _checkPrinterStatus() {
    if (this.props.member.userCredit <= 0) {
      Redux.showToast(
        "error",
        this.props.intl.formatMessage({
          id: "lang_insufficient_credit",
          defaultMessage: "lang_insufficient_credit",
        })
      );
      return;
    }

    if (this.props.printer_status) {
      this._printBalance();
    } else {
      Swal.fire({
        title: this.props.intl.formatMessage({
          id: "lang_printer_error",
          defaultMessage: "lang_printer_error",
        }),
        text: this.props.intl.formatMessage({
          id: "lang_printer_was_not_ready",
          defaultMessage: "lang_printer_was_not_ready",
        }),
        icon: "warning",
        timer: 1500,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }

  _printBalance() {
    Swal.fire({
      title: this.props.intl.formatMessage({
        id: "lang_withdraw_balance",
        defaultMessage: "lang_withdraw_balance",
      }),
      text: this.props.intl.formatMessage({
        id: "lang_are_you_sure_you_want_to_withdraw_your_balance",
        defaultMessage: "lang_are_you_sure_you_want_to_withdraw_your_balance",
      }),
      icon: "warning",
      confirmButtonText: this.props.intl.formatMessage({
        id: "lang_approve",
        defaultMessage: "lang_approve",
      }),
      denyButtonText: this.props.intl.formatMessage({
        id: "lang_cancel",
        defaultMessage: "lang_cancel",
      }),
      showDenyButton: true,
    }).then((response) => {
      if (response.isConfirmed) {
        this._createWithdrawTransaction();
      }
    });
  }

  _createWithdrawTransaction() {
    this.wc
      .post(
        constants.uris.wtpr,
        {},
        {
          setLang: true,
          setUserId: true,
        },
        false
      )
      .then((response) => {
        if (response.status) {
          if (response.transactionId != 0) {
            this._sendBalanceToPrint(response.transactionId);
            getUserData();
          } else {
            Redux.showToast(
              "error",
              this.props.intl.formatMessage({
                id: "lang_a_technical_error_occured_please_try_again_later",
                defaultMessage:
                  "lang_a_technical_error_occured_please_try_again_later",
              })
            );
          }
        } else {
          Redux.showToast("error", response.msg);
        }
      });
  }

  _sendBalanceToPrint(id) {
    let url = constants.prints.withdrawprint
      .replace("{{transactionId}}", id)
      .replace("{{lang}}", this.props.language)
      .replace("{{userId}}", this.props.member.userId);
  }

  componentWillUnmount() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }

  _onIdle() {
    if (this.socket) {
      _ss = false;
      this.socket.disconnect();
    }
    this.setState({ activeScreen: "IDLE" });
  }

  _refresh(e) {
    if (!_ss) {
      this.socket.init();
    }
    this.setState({ activeScreen: e });
  }

  render() {
    const {
      activeScreen,
      isLanguageOverlayOpen,
      showCoupon,
      showTicketCheck,
      showPasswordPanel,
      barcodeValue,
    } = this.state;
    const {
      intl,
      member,
      settings,
      show_ticket_detail,
      coupon_changed,
      coupon,
      coupon_errors,
    } = this.props;

    document.documentElement.lang = intl.locale;

    return (
      <div className="main-layout">
        <MainHeader
          onLangClick={() => {
            this.setState({ isLanguageOverlayOpen: true });
          }}
          onChange={(e) => {
            this._refresh(e);
          }}
          onTicketCheckClick={() => {
            this.setState({ showTicketCheck: true });
          }}
          onPrintBalanceClick={() => {
            this._checkPrinterStatus();
          }}
          onAdminLoginClick={() => {
            this.setState({ showPasswordPanel: true });
          }}
          activeScreen={activeScreen}
        />

        <div className="main-layout-bottom">
          <div className="screen-layout">
            {activeScreen == "LiveMatches" && (
              <LiveMatches
                onCouponOpen={() => {
                  this.openCoupon();
                }}
                onIdle={() => {
                  this._onIdle();
                }}
              />
            )}

            {activeScreen == "Matches" && (
              <Matches
                onCouponOpen={() => {
                  this.openCoupon();
                }}
              />
            )}

            {activeScreen == "LiveBingo" && <LiveBingo key={Date.now()} />}

            {activeScreen == "LiveCasino" && <LiveCasino onTicketCheckClick={() => {
            this.setState({ showTicketCheck: true });
          }}  />}

            {activeScreen == "SlotGames" && <SlotGames />}

            {activeScreen == "VirtualGames" && <VirtualGames    onTicketCheckClick={() => {
            this.setState({ showTicketCheck: true });
          }} />}

            {activeScreen == "Manager" && (
              <ManagerPage
              account={false} // Account değeri gönderiliyor
                onLogout={() => {
                  this.setState({ activeScreen: "LiveMatches" });
                }}
              />
            )}
    {activeScreen == "Manager2" && (
              <ManagerPage
              account={true} // Account değeri gönderiliyor
                onLogout={() => {
                  this.setState({ activeScreen: "LiveMatches" });
                }}
              />
            )}
            {activeScreen == "IDLE" && (
              <IdlePage
                onClose={() => {
                  this._refresh("LiveMatches");
                }}
              />
            )}
          </div>
        </div>

        {isLanguageOverlayOpen && (
          <Overlay
            onClose={() => {
              this.setState({ isLanguageOverlayOpen: false });
            }}
          >
            <IntlContext.Consumer>
              {(context) => {
                return (
                  <div className="choose-lang">
                    <h4>
                      <FormattedMessage
                        id="lang_choose_language"
                        defaultMessage="lang_choose_language"
                      />
                    </h4>
                    <div className="languages-cont">
                      {constants.languages.map((language, index) => {
                        if (language.lang == intl.locale) return null;

                        return (
                          <div
                            onClick={() => {
                              context.switchLanguage(language.lang);
                              LangChanger(language.lang);
                            }}
                            key={index}
                            className="language-item"
                          >
                            <span
                              className="icon"
                              style={{
                                backgroundImage: `url('${
                                  assets[language.lang]
                                }')`,
                              }}
                            ></span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              }}
            </IntlContext.Consumer>
          </Overlay>
        )}

        {showCoupon && (
          <div className="coupon-overlay">
            <div className="coupon-overlay-left">
              <span>
                <FormattedMessage
                  id="lang_credit"
                  defaultMessage="lang_credit"
                />
              </span>
              <span>{member?.userCredit}</span>
            </div>

            <div className="coupon-container">
              <Coupon
                onClose={() => {
                  this.closeCoupon();
                }}
              />
            </div>
          </div>
        )}

        {showTicketCheck && (
          <div className="coupon-check-overlay">
            <TicketCheck
              barcode={barcodeValue}
              onClose={() => {
                this.setState({ showTicketCheck: false, barcodeValue: "" });
              }}
            />
          </div>
        )}

        {showPasswordPanel && (
          <div className="coupon-check-overlay">
            <AdminLogin
              onLoginSuccess={(e) => {
                this.setState({ activeScreen: e, showPasswordPanel: false });
              }}
              onClose={() => {
                this.setState({ showPasswordPanel: false });
              }}
            />
          </div>
        )}

        {show_ticket_detail?.show && (
          <div className="coupon-overlay">
            <div
              onClick={() => {
                Redux.hideTicketDetail();
              }}
              className="coupon-detail-overlay-left"
            ></div>

            <div className="coupon-detail-container">
              <TicketDetail />
            </div>
          </div>
        )}

        {coupon_changed.show && (
          <div
            className="coupon-overlay"
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            <div className="w-1/2 p-10 bg-cgray-700 rounded-lg flex flex-col items-center justify-center">
              <h4 className="text-4xl text-cgray-100 font-bold mb-4">
                <FormattedMessage
                  id="lang_matches_changed"
                  defaultMessage="lang_matches_changed"
                />
              </h4>

              <p className="text-xl text-white mb-4 text-center">
                <FormattedMessage
                  id="lang_coupon_change_message"
                  defaultMessage="lang_coupon_change_message"
                />
              </p>

              <div className="w-full mb-10 px-4 bg-cgray-600 rounded-lg">
                {coupon_changed.list.map((item, index) => {
                  let _m = coupon?.filter((x) => x.id == item.matchid);
                  if (_m.length == 0) return null;
                  _m = _m[0];
                  let _o = _m?.odd?.filter((x) => x.ot == item.oddtypeid)[0];

                  return (
                    <div
                      key={index}
                      className="w-full flex flex-row items-center justify-between mt-4 mb-4"
                    >
                      <p className="w-3/6 text-white">
                        {_m.item.h} - {_m.item.a}
                      </p>
                      <p className="w-1/6 text-white text-right">{_o.tyn}</p>
                      <p className="w-1/6 text-white text-right">{_o.out}</p>
                      <p className="w-1/6 text-white text-right">
                        <span
                          className="text-red-400"
                          style={{
                            marginRight: 10,
                            textDecoration: "line-through",
                          }}
                        >
                          {item.oldOdd}
                        </span>
                        {item.newLock != 1 ? (
                          <span className="text-cgreen">{item.newOdd}</span>
                        ) : (
                          <i className="fas fa-lock" />
                        )}
                      </p>
                    </div>
                  );
                })}
              </div>

              <div className="w-full flex items-center justify-center flex-row">
                <button
                  onClick={() => {
                    Redux.setCouponChangedListShow(false);
                  }}
                  className="px-8 py-4 bg-blue-400 rounded-md text-white font-bold text-lg"
                >
                  <FormattedMessage
                    id="lang_close"
                    defaultMessage="lang_close"
                  />
                </button>
              </div>
            </div>
          </div>
        )}

        {coupon_errors.show && (
          <div
            className="coupon-overlay"
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            <div className="w-1/2 p-10 bg-cgray-700 rounded-lg flex flex-col items-center justify-center">
              <h4 className="text-4xl text-cgray-100 font-bold mb-4">
                <FormattedMessage
                  id="lang_matches_changed"
                  defaultMessage="lang_matches_changed"
                />
              </h4>

              <p className="text-xl text-white mb-4 text-center">
                <FormattedMessage
                  id="lang_coupon_change_message"
                  defaultMessage="lang_coupon_change_message"
                />
              </p>

              <div className="w-full mb-10 px-4 bg-cgray-600 rounded-lg">
                {coupon_errors.list.map((item, index) => {
                  let _m = coupon?.filter((x) => x.id == item.mid);
                  if (_m.length == 0) return null;
                  _m = _m[0];
                  let _o = _m?.odd?.filter((x) => x.ot == item.oddTypeId)[0];

                  return (
                    <div
                      key={index}
                      className="w-full flex flex-row items-center justify-between mt-4 mb-4"
                    >
                      <p className="w-3/6 text-white">
                        {_m.item.h} - {_m.item.a}
                      </p>
                      <p className="w-1/6 text-white text-right">{_o.tyn}</p>
                      <p className="w-1/6 text-white text-right">{_o.out}</p>
                      <p className="w-1/6 text-white text-right">
                        <i className="fas fa-lock" />
                      </p>
                    </div>
                  );
                })}
              </div>

              <div className="w-full flex items-center justify-center flex-row">
                <button
                  onClick={() => {
                    Redux.setCouponErrorListShow(false);
                  }}
                  className="px-8 py-4 bg-blue-400 rounded-md text-white font-bold text-lg"
                >
                  <FormattedMessage
                    id="lang_close"
                    defaultMessage="lang_close"
                  />
                </button>
              </div>
            </div>
          </div>
        )}

        {/* <TextInput value={emptyValue} onChangeText={(e) => { this.setState({emptyValue: e}) }} /> */}
      </div>
    );
  }
}

const msp = (state) => {
  return {
    config: state.config,
    member: state.member,
    settings: state.settings,
    show_ticket_detail: state.show_ticket_detail,
    initialized: state.main_window_initialized,
    coupon_changed: state.coupon_changed,
    coupon: state.coupon,
    coupon_errors: state.coupon_errors,
    printer_status: state.printer_status,
  };
};

export default connect(msp)(injectIntl(Main));
