import React from "react";
import { connect } from "react-redux";
import { Redux } from "../../../../components";
import { injectIntl } from "react-intl";
import classNames from "classnames";
import CouponOdd from "./coupon-odd";
import _ from "lodash";

class CouponMatch extends React.Component {
  formatDate(date) {
    const pad = (num) => String(num).padStart(2, '0');
    return `${pad(date.getDate())}/${pad(date.getMonth() + 1)} ${pad(date.getHours())}:${pad(date.getMinutes())}`;
  }

  _setBanko(is_selected, item, index) {
    const { coupon, intl } = this.props;

    let mbc = coupon.length - 2;
    let bc = coupon.filter((x) => x?.is_banko === true)?.length ?? 0;

    if (bc < mbc || is_selected) {
      Redux.setOddBanko(index, intl);
    }
  }

  render() {
    const {
      intl,
      coupon_settings,
      combinations,
      is_live,
      item,
      odd,
      index,
      coupon_changed,
      coupon_errors,
      cid,
      locked,
    } = this.props;

    let lck = locked;
    if (odd.find((x) => x.lck)) lck = true;

    let tscr = parseInt(item.scr?.h ?? 0) + parseInt(item.scr?.a ?? 0) + 1;

    return (
      <div
        className="coupon-item-cont"
        style={{ backgroundColor: lck ? "#636308" : "transparent" }}
      >
        <div className="coupon-item-left">
          <div className="coupon-item-sportname">{item.tn}</div>
          {item.l ? (
            <div className="coupon-item-sportname">
              {item.ms == "paused" ? (
                <span className="px-2 py-m bg-bghalftimeend text-lg text-white">
                  HT
                </span>
              ) : (
                <span className="px-2 py-m bg-liveend text-lg text-white">
                  {item.ms} {item.ti}'{item.tie > 0 ? `+${item.tie}` : null}
                </span>
              )}
              <span className="px-2 py-m bg-liveend text-lg text-white ml-2">
                {item.scr.h} - {item.scr.a}
              </span>
            </div>
          ) : (
            <div className="coupon-item-sportname">
              {this.formatDate(new Date(item.md))}
            </div>
          )}
          <div className="coupon-item-teams">
            <span>
              {item.h} - {item.a}
            </span>
          </div>
          {odd.map((o, yindex) => {
            lck = o.lck;
            return (
              <CouponOdd
                totalScore={tscr}
                key={o.sid}
                locked={lck}
                onLockChanged={() => {
                  this.setLocked();
                }}
                o={o}
                item={item}
                reset={() => {
                  this.props.reset();
                }}
              />
            );
          })}
        </div>
        <div className="coupon-item-right">
          <div
            onClick={() => {
              this.props.reset();
              Redux.removeMatchFromCoupon(item, odd, intl);
            }}
            className="coupon-item-delete"
          >
            <i className="fas fa-times" />
          </div>
          {coupon_settings.is_system_coupon_active &&
            combinations.filter((x) => x.selected).length > 0 && (
              <div
                onClick={() => {
                  this._setBanko(Boolean(item?.is_banko), item, index);
                }}
                className={classNames({
                  "coupon-item-banko": true,
                  selected: Boolean(item?.is_banko),
                })}
              >
                B
              </div>
            )}
        </div>
      </div>
    );
  }
}

const msp = (state) => {
  return {
    cid: state._cid,
    coupon: state.coupon,
    coupon_settings: state.coupon_settings,
    coupon_changed: state.coupon_changed,
    coupon_errors: state.coupon_errors,
  };
};

export default connect(msp)(injectIntl(CouponMatch));
