import React from "react";
import { FlatList } from "react-native";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Redux, UpDown, OddsFilter } from "../../../../components";
import Coupon from "../coupon";
import ClassicStats from "./classic-stats";
import _ from "lodash";
import classNames from "classnames";
import MatchMarketGroup from "./match-market-group";
import Market from "../../../../components/models/market";

let scrollM = 0;
let h = 300;

let exclude_markets = [5504];

class CStatisticIframe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showStatistics: false,
      onlyFavorites: false,
      item: props.match,
      activeTab: 0,
      favoriteCount: 0,
      favorites: [],
    };
    this.oddListDiv = React.createRef();
  }

  componentDidMount() {
    let favs = localStorage.getItem("favorite_odd_types");
    if (favs != null) {
      this.setState({
        favorites: JSON.parse(favs),
        favoriteCount: JSON.parse(favs).length,
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState?.activeTab != this.state.activeTab) {
      return true;
    }

    if (nextState?.onlyFavorites != this.state.onlyFavorites) {
      return true;
    }

    if (nextState?.showStatistics != this.state.showStatistics) {
      return true;
    }

    if (nextState?.favorites != this.state.favorites) {
      return true;
    }

    if (nextState?.favoriteCount != this.state.favoriteCount) {
      return true;
    }

    return false;
  }

  _setActiveTab(activeTab, i) {
    this.setState({ activeTab, onlyFavorites: false });
    this.l.scrollToIndex({ animated: false, index: "" + i > 0 ? i - 1 : i });
  }

  _close() {
    this.props.match.detail_showing = false;
    this.props.onClose();
  }

  addOrRemoveFromFavorites(i) {
    let a = [];
    let favs = localStorage.getItem("favorite_odd_types");
    if (favs != null) {
      a = JSON.parse(favs);
      let b = a.indexOf(i);
      if (b != -1) {
        a.splice(b, 1);
      } else {
        a.push(i);
      }
    } else {
      a.push(i);
    }
    localStorage.setItem("favorite_odd_types", JSON.stringify(a));
    // this.setState({favorites: a})
  }

  render() {
    const {
      showStatistics,
      activeTab,
      favorites,
      onlyFavorites,
      favoriteCount,
    } = this.state;
    const { data, match, coupon, intl, member, activeSport } = this.props;

    let oddsFilter =
      OddsFilter[activeSport?.sportId ?? activeSport?.id ?? 1] ?? [];


const marketData = data.map((d) => new Market(d, match));

 let group = [];
 marketData?.map((e, i) => {
  let _a = group.find((x) => x.id == e.id);
  let _b = e.o.filter((x) => x.isv && !x.iss);
  if (_a && _b.length > 0) {
    _a.odds.push(..._b);
  } else if (!_a && _b.length > 0) {
    group.push({
      id: e.id,
      tyn: e.tyn,
      odds: _b,
    });
  }
});


    group
      .filter((x) => !exclude_markets.includes(x.id))
      .map((g) => {
        if (g.odds.find((x) => x.sp.split("*")[0] != "")) {
          g.odds = _.orderBy(g.odds, ["sp"], ["asc"]);
        }
      });



    oddsFilter.map((o, index) => {
      let show = Boolean(o.id == 0);
      let _c = 0;

      if (Boolean(o.id != 0)) {
        let ids = o.ids;
        let g = group.filter((x) => ids.includes(x.id)) ?? [];
        _c = g.filter(
          (x) => x.odds.filter((x) => x.isv && !x.iss).length > 0
        ).length;
        show = Boolean(_c > 0);
      } else {
        _c = group.filter(
          (x) => x.odds.filter((x) => x.isv && !x.iss).length > 0
        ).length;
      }

      o.show = show;
      o.count = _c;
    });

    const filtered = oddsFilter.filter((x) => x.show == true);

    let url = "";
    if (match.ms != "not_started") url = Redux.getLiveStatisticsUrl(match);

    let final = group;
    if (activeTab != 0)
      final = group.filter((x) => oddsFilter[activeTab]?.ids?.includes(x.id));

    if (onlyFavorites) final = group.filter((x) => favorites.includes(x.id));
    final.sort((a, b) => a.id - b.id);
    if (!match.l && match?.istt) url = Redux.getStatisticsUrl(match.id);

    let w = window.innerWidth * 0.833333;
    let h = (w / 2) * 0.548238;

    return (
      <div className="coupon-overlay bg-cgray-700">
        <div className="live-detail-left">
          <div className="w-full p-2">
            <div className="live-detail-title">
              <div className="live-detail-title-col">
                {/* <img src={`https://s.betimg.com/ekol/uploads/flags/${match.cid}.png`} /> */}
                <span>{match.cn}</span>
              </div>

              <span className="live-detail-title-col">
                {match.h} - {match.a}
              </span>

              <span className="live-detail-title-col">{match.tn}</span>
            </div>
          </div>

          <div
            ref={(ref) => {
              this.scrollable = ref;
            }}
            className="live-matches-left-b hide-scrollbar"
          >
            <div
              className="w-full bg-cgray-700 flex flex-row items-center justify-center px-2 mb-2"
              style={{ height: h }}
            >
              <div className="w-1/2 h-full">
                {match.l ? (
                  <iframe
                    id="ApiId"
                    src={url.url}
                    width="100%"
                    height="100%"
                    scrolling="no"
                    frameborder="0"
                  ></iframe>
                ) : (
                  <iframe
                    style={{ width: "100%", height: "100%" }}
                    src={url}
                  ></iframe>
                )}
              </div>
              <div className="w-1/2 h-full flex flex-center justify-center">
                <ClassicStats match={match} />
              </div>
            </div>

            {match.bs != "stopped" ? (
              <div className="w-full px-2">
                <div className="market-tabs mb-1">
                  <div className="market-tabs-middle">
                    <FlatList
                      ref={(ref) => {
                        this.l = ref;
                      }}
                      data={filtered}
                      showsHorizontalScrollIndicator={false}
                      showsVerticalScrollIndicator={false}
                      horizontal={true}
                      renderItem={({ item, index }) => {
                        return (
                          <div
                            key={`tab-${index}`}
                            onClick={() => {
                              this._setActiveTab(item.id, index);
                            }}
                            className={classNames("market-tab-item", {
                              selected: Boolean(activeTab == item.id),
                            })}
                          >
                            <FormattedMessage
                              id={item.title}
                              defaultMessage={item.title}
                            />{" "}
                            ({item.count})
                          </div>
                        );
                      }}
                    />
                  </div>
                </div>

                <div className="market-groups">
                  {final.map((g, index) => {
                    return (
                      <MatchMarketGroup
                        key={`${index}-${g.id}`}
                        g={g}
                        match={match}
                        matchId={match.id}
                        favorites={favorites}
                        changeFavorites={(e) => {
                          this.setState({
                            favorites: e,
                            favoriteCount: e.length,
                          });
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="w-full text-center py-4 text-xl text-white">
                <FormattedMessage
                  id="lang_there_is_no_active_market_for_this_match"
                  defaultMessage="lang_there_is_no_active_market_for_this_match"
                />
              </div>
            )}
          </div>

          {match.betstatus != "stopped" && (
            <UpDown
              size="md"
              mb="mb-0"
              upClick={() => {
                this.scrollable.scrollTo({
                  behavior: "smooth",
                  top: this.scrollable.scrollTop - this.scrollable.clientHeight,
                });
              }}
              downClick={() => {
                this.scrollable.scrollTo({
                  behavior: "smooth",
                  top: this.scrollable.scrollTop + this.scrollable.clientHeight,
                });
              }}
            />
          )}
        </div>

        <div className="coupon-container">
          <Coupon
            onClose={() => {
              this._close();
            }}
          />
        </div>
      </div>
    );
  }
}

const msp = (state) => {
  return {
    config: state.config,
    coupon: state.coupon,
    member: state.member,
    _mid: state._mid,
  };
};

export default connect(msp)(injectIntl(CStatisticIframe));
