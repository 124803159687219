import React from 'react'
import { FormattedMessage } from 'react-intl'
import { FlatList } from 'react-native'
import { connect } from 'react-redux'
import { Redux, WebClient, constants, UpDown } from '../../../../components'

let scrollM = 0

class RightList extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
  }

  render() {
    const { items, subItems, member } = this.props
    let w = window.innerWidth;
    let ww = (w * 80) / 100
    let h = (ww / 3) * 0.6650;



    return (
      <>
        <div className={`r-w-arrows-in ${subItems.length > 1 && ("w-sub")}`}>
          <FlatList
            ref={(ref) => { this.mainList = ref }}
            style={{ width: "100%", height: "100%", padding: 2 }}
            data={items}
            numColumns={3}
            onScroll={(e) => { scrollM = e.nativeEvent.contentOffset.y }}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            renderItem={({item, index}) => {
              let extras = null
              if (item?.extras) extras = JSON.parse(item.extras)
              return (
                <div
                  onClick={() => { 
                    if (member.userCredit > 0) {
                      this.props.onItemClick(item)
                    }
                  }}
                  key={index}
                  className={`right-list-item ${member.userCredit <= 0 ? 'disabled' : ''}`}
                  style={{ height: h, cursor: member.userCredit > 0 ? 'pointer' : 'not-allowed' }}
                >
                  <div className="right-list-item-in" style={{ backgroundImage: `url('${extras?.device?.logo ?? item.logo}')` }}>
                    <div className="flex flex-col items-center justify-center">
                      <span className="title">{item.title}</span>
                      {
                        extras?.limitMin && (
                          <span className="limit">
                            <FormattedMessage id="lang_limit" defaultMessage="lang_limit" /> : {extras.limitMin} - {extras.limitMax}
                          </span>
                        )
                      }
                    </div>
                  </div>
                </div>
              )
            }}
          />
        </div>
        <UpDown
          disabled={Boolean(items.length < 7)}
          size="md"
          upClick={() => { this.mainList.scrollToOffset({ animated: true, offset: scrollM - h }) }}
          downClick={() => { this.mainList.scrollToOffset({ animated: true, offset: scrollM + h }) }}
        />
      </>
    );
  }
}

const msp = (state) => {
  return {
    config: state.config,
    member: state.member
  }
}

export default connect(msp)(RightList)