import React from 'react'

export default class Clock extends React.Component {

  state = {
    time: 0
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState({time: this.state.time + 1})
    }, 1000)
  }

  componentWillUnmount() {
    if (this.interval != undefined || this.interval !== null) {
      clearInterval(this.interval)
    }
  }

  formatDate(date) {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); 
    const year = d.getFullYear();
    const hours = String(d.getHours()).padStart(2, '0');
    const minutes = String(d.getMinutes()).padStart(2, '0');
    const seconds = String(d.getSeconds()).padStart(2, '0');
    
    return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
  }

  render() {
    return this.formatDate(new Date())
  }
}
