import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { constants } from '../../../../components/'
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

let layout = {
      default: [
          '1 2 3 4 5 6 7 8 9 0 {bksp}',
          "q w e r t y u ı o p ğ ü",
          "{lock} a s d f g h j k l ş i",
          "{shift} z x c v b n m ö ç",
          "{space} {close} {search}",
      ],
      shift: [
          "1 2 3 4 5 6 7 8 9 0 {bksp}",
          "Q W E R T Y U I O P Ğ Ü",
          "{lock} A S D F G H J K L Ş İ",
          "{shift} Z X C V B N M Ö Ç",
          "{space} {close} {search}",
      ],
}

let buttonTheme = [
    {
      class: "keyboard-close",
      buttons: "{close}"
    },
    {
      class: "keyboard-search",
      buttons: "{search}"
    },
    {
      class: "keyboard-caps",
      buttons: "{space} {lock} {shift} {bksp}"
    },    
]

class SearchOverlay extends React.Component {

    state = {
        input: ""
    }

    componentDidMount() {
        this.setState({input: this.props.keyword})
        // this.keyboard.setInput(this.props.keyword)
    }

    onChange = (input) => {
        this.setState({input})

        this.props.onChange(input)
    }

    onKeyPress = (button) => {
     
        if (button === "{shift}" || button === "{lock}") this.handleShift();
        if (button === "{close}") {
            this.setState({input: ""}, () => {
                this.props.onClose()
            })
        }
        if (button === "{search}") {
            if (this.state.input?.length > 3) {
                this.props.onSearch()
            }
        }
    }

    handleShift() {
        let currentLayout = this.keyboard.options.layoutName;
        let shiftToggle = currentLayout === "default" ? "shift" : "default";

        this.keyboard.setOptions({
            layoutName: shiftToggle
        });
    }

    render() {
        const { input } = this.state
        const { intl } = this.props
        return (
        <div className="search-overlay">

            <div className="w-2/3">
                <div className="search-overlay-input">
                    <FormattedMessage id="lang_match_search" defaultMessage="lang_match_search" /> : {input}
                </div>
            </div>

            <div className="w-2/3">
                <Keyboard
                    keyboardRef={(ref) => this.keyboard = ref}
                    layout={layout}
                    buttonTheme={buttonTheme}
                    display={{
                        "{search}": intl.formatMessage({id: "search"}),
                        "{space}": intl.formatMessage({id: "Space"}),
                        "{close}": intl.formatMessage({id: "close"}),
                        "{bksp}": intl.formatMessage({id: "<"}),
                        "{lock}": intl.formatMessage({id: "CAPS"}),
                        "{shift}": intl.formatMessage({id: "SHIFT"})
                    }}
                    onChange={this.onChange}
                    onKeyPress={this.onKeyPress}
                />
            </div>
        </div>
        );
    }
}

export default injectIntl(SearchOverlay);