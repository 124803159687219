import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Redux, UpDown, OddsFilter } from "../../../../components";
import _ from "lodash";
import classNames from "classnames";

class LiveMatchMarketGroup extends React.Component {
  static defaultProps = {
    g: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      _mid: props._mid,
      matchId: props.matchId,
      is_open: true,
      favorites: props.favorites,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps?.favorites != this.state.favorites) {
      this.setState({ favorites: nextProps.favorites });
      return true;
    }

    if (nextProps?._mid == this.state.matchId) {
      return true;
    }

    if (nextState.is_open != this.state.is_open) {
      return true;
    }

    if (nextState.favorites != this.state.favorites) {
      return true;
    }

    return false;
  }

  _removeAnimate(mid, oid) {
    this.timeout = setTimeout(() => {
      oid.an = "";
      Redux.setMid(mid);
      clearTimeout(this.timeout);
    }, 1000);
  }

  _replace(o) {
    return o
      .replace("{t1}", this.props.match.h)
      .replace("{t2}", this.props.match.a)
      .replace("{$competitor1}", this.props.match.h)
      .replace("{$competitor2}", this.props.match.a)
      .replace(/\{.*?\}/g, "")
      .replace(/\(.*?\)/, "");
  }

  _getGroup(e) {
    if (
      e == 18 ||
      e == 5501 ||
      e == 5502 ||
      e == 6593 ||
      e == 7642 ||
      e == 11164 ||
      e == 11165 ||
      e == 11214 ||
      e == 10591 ||
      e == 8967 ||
      e == 5511
    ) {
      return "2";
    }

    return "3";
  }

  addOrRemoveFavorites(i) {
    let a = this.state.favorites;
    let b = a.indexOf(i);
    if (b != -1) {
      a.splice(b, 1);
    } else {
      a.push(i);
    }
    localStorage.setItem("favorite_odd_types", JSON.stringify(a));
    this.setState({ favorites: a });
    this.props.changeFavorites(a);
    Redux.setMid(this.props.matchId);
  }

  render() {
    const { is_open, favorites } = this.state;
    const { g, matchId, match, coupon, intl, h, a } = this.props;

    if (match.bs == "stopped") return null;
    if (g.odds.filter((x) => !x.iss && x.isv).length == 0) return null;
    let handicap = !Boolean(
      g.odds.filter((x) => x.handicap != false).length == 0
    );

    return (
      <div className="market-group">
        <div className="market-group-in">
          <div className="market-group-title">
            {g.tyn}

            <div className="flex flex-row items-center h-full">
              <div
                onClick={() => {
                  this.addOrRemoveFavorites(g.id);
                }}
                className={classNames(
                  "h-full px-4 flex items-center justify-center",
                  {
                    "text-yellow-500": Boolean(
                      favorites.find((x) => x == g.id)
                    ),
                  }
                )}
              >
               
              </div>
              <div
                onClick={() => {
                  this.setState({ is_open: !is_open });
                }}
                className="h-full px-4 flex items-center justify-center"
              >
                <i
                  className={`fas ${
                    is_open ? "fa-chevron-up" : "fa-chevron-down"
                  }`}
                ></i>
              </div>
            </div>
          </div>
          {is_open && (
            <div
              className={classNames("market-group-odds", {
                [`group-${handicap ? "4" : this._getGroup(g.id)}`]: true,
              })}
            >
        {
       
        g.odds.map((o, index) => {
  const st = o?.st ?? "locked";

  const is_added = coupon.some(
    (x) => x.id === match.id && x.odd.some((odd) => odd.uq === o?.uq)
  );

  return (
    <React.Fragment key={`${o.uq || index}`}>
      {handicap && index % 3 === 0 && (
        <div className="market-group-handicap">
          <div className="market-group-handicap-in">{o.handicap}</div>
        </div>
      )}

      <div
        className={classNames("market-group-odd", {
          [`odd-${st}`]: st.length > 0,
          "in-coupon": is_added,
        })}
      >
        <div className="outcome">
          {this._replace(o.out)}
          {o?.spv && !handicap ? `(${o.spv})` : ""}
        </div>

        {st === "" ? (
          <div
            onClick={() => {
              Redux.addOrRemoveOdd(match, o, intl);
              Redux.setMid(matchId);
            }}
            className="odds"
          >
            {o.o}
            {o?.an?.length > 0 && (
              <>
                <span className={`fas market-animate-${o.an}`}></span>
                {this._removeAnimate(match.id, o)}
              </>
            )}
          </div>
        ) : (
          <div className="odds">
            <i className="fas fa-lock" />
          </div>
        )}
      </div>
    </React.Fragment>
  );
})}

            </div>
          )}
        </div>
      </div>
    );
  }
}

const msp = (state) => {
  return {
    config: state.config,
    coupon: state.coupon,
    member: state.member,
    _mid: state._mid,
  };
};

export default connect(msp)(injectIntl(LiveMatchMarketGroup));
