import React from 'react'
import { FlatList } from 'react-native'
import { connect } from 'react-redux'
import { Redux, WebClient, constants, UpDown, CFormatDate, CButton } from '../../../../components'
import { FormattedMessage, injectIntl, FormattedDate, FormattedTime } from 'react-intl'
import classNames from 'classnames'
import Swal from 'sweetalert2/src/sweetalert2.js'


let divHeight = 0
let pages = 1
let activePage = 1
let limit = 0

class AdminTransactions extends React.Component {

  constructor(props) {
    super(props)
    this.wc = new WebClient();
    this.state = {
      transactions: []
    }
  } 

  componentDidMount() {

    setTimeout(() => {
        divHeight = this.divElement.clientHeight - 50.75
        let b = Math.ceil(divHeight / 67.8)
        limit = b - 1
        pages = 1
        activePage = 1

        this._getTransactions()

    }, 200)

  }

  _getTransactions() {

    this.wc.post(constants.uris.utrs, 
      {
        limit: limit,
        order: (limit * (activePage - 1))
      }, 
      {
        setReqId: true,
        setUserId: true,
        setLang: true
      }).then((response) => {
        if (response.status) {

          let a = response.results
          let c = Math.ceil(response.totalCount / (limit))

          pages = c;

          this.setState({activeTab: 2, transactions: a})
        } else {
          Redux.showToast(
            "error",
            response.msg
          )
        }

      })

}  

  _nextPage() {
    
    if (activePage < pages) {

        activePage = activePage + 1
        this._getTransactions()

    }

  }

  _previousPage() {
    
    if (activePage > 1) {
        activePage = activePage - 1
        this._getTransactions()
    }

  }  

  render() {
    const { transactions } = this.state
    return (
        <>
        <div ref={(ref) => { this.divElement = ref }} className="manager-content">
        <table className="ticket-result-table">
            <thead>
                <tr>
                    <th><FormattedMessage id="lang_type" defaultMessage="lang_type" /></th>
                    <th><FormattedMessage id="lang_date" defaultMessage="lang_date" /></th>
                    <th><FormattedMessage id="lang_process" defaultMessage="lang_process" /></th>
                    <th><FormattedMessage id="lang_quantity" defaultMessage="lang_quantity" /></th>
                </tr>
            </thead>
            <tbody>
                {
                transactions.map((item, index) => {
                    return (
                    <tr className={classNames({
                            'st-in': Boolean(item?.transType == 1),
                            'st-out': Boolean(item?.transType == 0)
                        })} 
                        key={index}>
                        <td><FormattedMessage id={item?.transType == 1 ? "lang_in" : "lang_out"} defaultMessage={item?.transType == 1 ? "lang_in" : "lang_out"} /></td>
                        <td><CFormatDate date={item.processDate} /></td>                          
                        <td>{item?.title} ({item?.ticketId})</td>
                        <td>{item?.amount}</td>
                    </tr>
                    )
                })
                }
            </tbody>
        </table> 
        </div>
        <div className="bottom-pagination-cont">
        <CButton width="w-1/3" icon={true} iconName="fa-chevron-left" onClick={() => { this._previousPage() }} />
        <div className="live-pagination-cont" style={{ width: "33%" }}>
            {activePage} / {pages}
        </div>
        <CButton width="w-1/3" icon={true} iconName="fa-chevron-right" onClick={() => { this._nextPage() }} />
        </div>
        </>        
    );
  }
}

const msp = (state) => {
  return {
      config: state.config
  }
}

export default connect(msp)(injectIntl(AdminTransactions))
