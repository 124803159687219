import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import _ from "lodash";
import LiveMatchRow from "./live-match-row";
import { VariableSizeList as List } from "react-window";

class MatchList extends React.Component {
  static defaultProps = {
    showTitle: false,
    index: 0,
  };

  constructor(props) {
    super(props);
    this.interval = undefined;
    this.state = {
      sc: props.sc,
      onlyFavorites: props.onlyFavorites,
      schema: props.schema,
      relist: false,
    };
  }

  _set() {
    this.interval = setInterval(() => {
      this.setState({ relist: !this.state.relist });
    }, 50000);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps?.sc != this.state.sc) {

      this.setState({ sc: nextProps.sc });
      // this.list.resetAfterIndex(0)
      return true;
    }

    if (nextProps?.schema != this.state.schema) {
      this.setState({ schema: nextProps.schema });
      return true;
    }

    if (nextProps?.onlyFavorites != this.state.onlyFavorites) {
      this.setState({ onlyFavorites: nextProps.onlyFavorites });
      return true;
    }

    if (nextState.relist != this.state.relist) {
      return true;
    }

    return false;
  }

  componentWillUnmount() {
    if (this.interval) clearInterval(this.interval);
  }

  render() {
    const {
      live_matches,
      schema,
      z,
      l,
      orderBy,
      showAllList,
      halfTime,
      onlyFavorites,
      dh,
      time,
    } = this.props;

    let matches = live_matches;

    const row_height = 58;
    const row_height_fh = 116;

    if (orderBy == 1) {
      matches = _.orderBy(matches, ["tio"], ["desc"]);
    } else {
      matches = _.orderBy(
        matches,
        ["l", "cid", "tid", "tio"],
        ["desc", "asc", "asc", "desc"]
      );
    }

    if (onlyFavorites) {
      matches = matches.filter((x) => x.is_favorite == true);
    }



    return (
      <>
        {matches.map((item, index) => {
          return (
            <LiveMatchRow
              key={item.id}
              z={z}
              l={l}
              schema={schema}
              item={item}
              oe={index % 2 == 0 ? "odd" : "even"}
              halfTime={halfTime}
              onShowStatistics={this.props.onShowStatistics}
              onAddFavorite={this.props.onAddFavorite}
              onDetail={this.props.onDetail}
            />
          );
        })}
      </>
    );
  }
}

const msp = (state) => {
  return {
    live_matches: state.live_matches,
    sc: state._statusChanged,
  };
};

export default connect(msp)(injectIntl(MatchList));
