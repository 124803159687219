import React from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import moment from 'moment'
import classNames from 'classnames'

class ClassicStats extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            mid: props.match.id
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState?.mid == this.state.mid) {
            return true
        }

        return false;
    }

    formatMatchDate(date) {
        const d = new Date(date);
        const day = String(d.getDate()).padStart(2, '0');
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const hours = String(d.getHours()).padStart(2, '0');
        const minutes = String(d.getMinutes()).padStart(2, '0');
        return `${day}.${month} - ${hours}:${minutes}`;
    }

    render() {
        const { match } = this.props

        return (
            <div className={classNames({"classic-stat-cont": true, [`type-${match.sid}`]: true})}>

                {
                match.sid == 1 && (
                <div className="classic-stats">

                    <div className="classic-stats-title">
                    <div className="team">
    {match.ms ? (
        match.ms === "paused" ? (
            "HT"
        ) : match.ms === "not_started" ? (
            this.formatMatchDate(match.md)
        ) : match.ms !== "ended" ? (
            `${match.ms} - ${match.ti}'`
        ) : null
    ) : (
        match.d // match.ms yoksa match.d'yi göster
    )}
</div>

                        <div className="title-col yellow-card"></div>
                        <div className="title-col red-card"></div>
                        <div className="title-col corner"></div>
                        <div className="title-col">1</div>
                        <div className="title-col">2</div>
                        <div className="title-col"><FormattedMessage id="lang_ms" defaultMessage="lang_ms" /></div>
                    </div>

                    <div className="classic-stats-row">
                        <div className="team">{match.h}</div>
                        <div className="row-col">{match?.stt?.c?.y?.h ?? 0}</div>
                        <div className="row-col">{match?.stt?.c?.r?.h ?? 0}</div>
                        <div className="row-col">{match?.stt?.cr?.h ?? 0}</div>
                        <div className="row-col">{match.l ? match?.sscr[0]?.h ?? 0 : 0}</div>
                        <div className="row-col">{match.l ? match?.sscr[1]?.h ?? 0 : 0}</div>
                        <div className="row-col">{match?.scr?.h ?? 0}</div>
                    </div>
                    <div className="classic-stats-separator"></div>
                    <div className="classic-stats-row">
                        <div className="team">{match.a}</div>
                        <div className="row-col">{match?.stt?.c?.y?.a ?? 0}</div>
                        <div className="row-col">{match?.stt?.c?.r?.a ?? 0}</div>
                        <div className="row-col">{match?.stt?.cr?.a ?? 0}</div>
                        <div className="row-col">{match.l ? match?.sscr[0]?.a ?? 0 : 0}</div>
                        <div className="row-col">{match.l ? match?.sscr[1]?.a ?? 0 : 0}</div>
                        <div className="row-col">{match?.scr?.a ?? 0}</div>
                    </div>                        

                </div>
                )
                }

                {
                match.sid == 3 && (
                <div className="classic-stats">

                    <div className="classic-stats-title">
                        <div className="team">
                            {match.ms == "paused" && ("HT")}
                            {match.ms == "not_started" && (this.formatMatchDate(match.md))}
                            {(match.ms != "paused" && match.ms != "ended" && match.ms != "not_started") && (`${match.ms} - ${match.ti}'`)}
                        </div>
                        <div className="title-col">1</div>
                        <div className="title-col">2</div>
                        <div className="title-col">3</div>
                        <div className="title-col">4</div>
                        <div className="title-col"><FormattedMessage id="lang_ms" defaultMessage="lang_ms" /></div>
                    </div>

                    <div className="classic-stats-row">
                        <div className="team">{match.h}</div>
                        <div className="row-col">{match.l ? match?.sscr[0]?.h ?? 0 : 0}</div>
                        <div className="row-col">{match.l ? match?.sscr[1]?.h ?? 0 : 0}</div>
                        <div className="row-col">{match.l ? match?.sscr[2]?.h ?? 0 : 0}</div>
                        <div className="row-col">{match.l ? match?.sscr[3]?.h ?? 0 : 0}</div>
                        <div className="row-col">{match?.scr?.h ?? 0}</div>
                    </div>
                    <div className="classic-stats-separator"></div>
                    <div className="classic-stats-row">
                        <div className="team">{match.a}</div>
                        <div className="row-col">{match.l ? match?.sscr[0]?.a ?? 0 : 0}</div>
                        <div className="row-col">{match.l ? match?.sscr[1]?.a ?? 0 : 0}</div>
                        <div className="row-col">{match.l ? match?.sscr[2]?.a ?? 0 : 0}</div>
                        <div className="row-col">{match.l ? match?.sscr[3]?.a ?? 0 : 0}</div>
                        <div className="row-col">{match?.scr?.a ?? 0}</div>
                    </div>                        

                </div>
                )
                } 

                {
                match.sid == 4 && (
                <div className="classic-stats">

                    <div className="classic-stats-title">
                        <div className="team">
                            {match.ms == "paused" && ("HT")}
                            {match.ms == "not_started" && (this.formatMatchDate(match.md))}
                            {(match.ms != "paused" && match.ms != "ended" && match.ms != "not_started") && (`${match.ms} - ${match.ti}'`)}
                        </div>
                        <div className="title-col">1</div>
                        <div className="title-col">2</div>
                        <div className="title-col">3</div>
                        <div className="title-col">4</div>
                        <div className="title-col">5</div>
                        <div className="title-col"><FormattedMessage id="lang_ms" defaultMessage="lang_ms" /></div>
                    </div>

                    <div className="classic-stats-row">
                        <div className="team">{match.h}</div>
                        <div className="row-col">{match.l ? match?.sscr[0]?.h ?? 0 : 0}</div>
                        <div className="row-col">{match.l ? match?.sscr[1]?.h ?? 0 : 0}</div>
                        <div className="row-col">{match.l ? match?.sscr[2]?.h ?? 0 : 0}</div>
                        <div className="row-col">{match.l ? match?.sscr[3]?.h ?? 0 : 0}</div>
                        <div className="row-col">{match.l ? match?.sscr[4]?.h ?? 0 : 0}</div>
                        <div className="row-col">{match?.scr?.h ?? 0}</div>
                    </div>
                    <div className="classic-stats-separator"></div>
                    <div className="classic-stats-row">
                        <div className="team">{match.a}</div>
                        <div className="row-col">{match.l ? match?.sscr[0]?.a ?? 0 : 0}</div>
                        <div className="row-col">{match.l ? match?.sscr[1]?.a ?? 0 : 0}</div>
                        <div className="row-col">{match.l ? match?.sscr[2]?.a ?? 0 : 0}</div>
                        <div className="row-col">{match.l ? match?.sscr[3]?.a ?? 0 : 0}</div>
                        <div className="row-col">{match.l ? match?.sscr[5]?.a ?? 0 : 0}</div>
                        <div className="row-col">{match?.scr?.a ?? 0}</div>
                    </div>                        

                </div>
                )
                }  

                {
                match.sid == 5 && (
                <div className="classic-stats">

                    <div className="classic-stats-title">
                        <div className="team">
                            {match.ms == "paused" && ("HT")}
                            {match.ms == "not_started" && (this.formatMatchDate(match.md))}
                            {(match.ms != "paused" && match.ms != "ended" && match.ms != "not_started") && (`${match.ms} - ${match.ti}'`)}
                        </div>
                        <div className="title-col">1</div>
                        <div className="title-col">2</div>
                        <div className="title-col">3</div>
                        <div className="title-col">4</div>
                        <div className="title-col">5</div>
                        <div className="title-col"><FormattedMessage id="lang_ms" defaultMessage="lang_ms" /></div>
                    </div>

                    <div className="classic-stats-row">
                        <div className="team">{match.h}</div>
                        <div className="row-col">{match.l ? match?.sscr[0]?.h ?? 0 : 0}</div>
                        <div className="row-col">{match.l ? match?.sscr[1]?.h ?? 0 : 0}</div>
                        <div className="row-col">{match.l ? match?.sscr[2]?.h ?? 0 : 0}</div>
                        <div className="row-col">{match.l ? match?.sscr[3]?.h ?? 0 : 0}</div>
                        <div className="row-col">{match.l ? match?.sscr[4]?.h ?? 0 : 0}</div>
                        <div className="row-col">{match?.scr?.h ?? 0}</div>
                    </div>
                    <div className="classic-stats-separator"></div>
                    <div className="classic-stats-row">
                        <div className="team">{match.a}</div>
                        <div className="row-col">{match.l ? match?.sscr[0]?.a ?? 0 : 0}</div>
                        <div className="row-col">{match.l ? match?.sscr[1]?.a ?? 0 : 0}</div>
                        <div className="row-col">{match.l ? match?.sscr[2]?.a ?? 0 : 0}</div>
                        <div className="row-col">{match.l ? match?.sscr[3]?.a ?? 0 : 0}</div>
                        <div className="row-col">{match.l ? match?.sscr[5]?.a ?? 0 : 0}</div>
                        <div className="row-col">{match?.scr?.a ?? 0}</div>
                    </div>                        

                </div>
                )
                }                                  
                
                         

            </div>
        );
    }
}

const msp = (state) => {
  return {
      mid: state._mid
  }
}

export default connect(msp)(injectIntl(ClassicStats))
