import React from 'react'
import { connect } from 'react-redux'
import { Redux, WebClient, constants, Overlay, UpDown, getUserData, CFormatDate } from '../../../../components'
import { FormattedMessage, injectIntl } from 'react-intl'
import classNames from 'classnames'
import { FlatList } from 'react-native'
import _ from "lodash"

let ct = "combine"
let coupon_number = 0;
let banko_count = 0;
let banko_control_count = 0;
let max_banko_count = 0;

class TicketDetail extends React.Component {


    constructor(props) {
        super(props)
        this.wc = new WebClient();
        this.state = {
            showCouponTypeModal: false,
            activeAmountTrigger: 1,
            betAmount: 1,
            selectedCombine: 0,
            selectedCombinations: [],
            tempSelectedCombinations: []
        }
    }

    render() {
        const { activeAmountTrigger, betAmount, showCouponTypeModal, selectedCombine, selectedCombinations, tempSelectedCombinations } = this.state
        const { intl, show_ticket_detail } = this.props

        const { coupon, canceled, results, sellType  } = show_ticket_detail.data

        let total_rate = 0;
        let disabled = true;
        let combinations = []
        return (
        <div className="coupon-cont">

            <button onClick={() => { Redux.hideTicketDetail() }} className="close-coupon">
                <FormattedMessage id="lang_close" defaultMessage="lang_close" />
            </button>

            <div className="coupon-title-detail">

                <FormattedMessage id={Redux.getCouponTypeName(coupon?.result?.ticketType)} defaultMessage={Redux.getCouponTypeName(coupon?.result?.ticketType)} /> 
                <span className="ml-1">({coupon?.result?.totalTickets})</span>
                
            </div>

            <div className="coupon-detail-middle">

                {
                (results?.length > 0) && (
                <FlatList
                    data={results}
                    style={{ width: "100%", height: "100%" }}
                    showsVerticalScrollIndicator={false}
                    showsHorizontalScrollIndicator={false}
                    renderItem={({item, index}) => {
                        return (
                            <div key={index} className="coupon-item-cont">
                                <div className={classNames({
                                        "coupon-detail-item-left": Boolean(coupon?.result?.ticketType != "SK"),
                                        "coupon-item-left": Boolean(coupon?.result?.ticketType == "SK")
                                        })}>
                                    <div className="coupon-item-sportname">{item.sportName} - {item.tournamentName}</div>
                                    <div className="coupon-item-sportname"><CFormatDate justifyContent="flex-start" date={item.matchDate} /></div>
                                    <div className="coupon-item-teams">
                                        <span>{item.home} - {item.away}</span>
                                    </div>
                                    <div className="coupon-item-odd">
                                        <span>{item.typeName} - {item.outComeName}</span>
                                        <div className="flex flex-row items-center">
                                            <span>{item.odds}</span>
                                        </div>
                                    </div>
                                    <div className="coupon-item-odd">
                                        <span><FormattedMessage id="results" /></span>
                                        <div className="flex flex-row items-center">
                                            <span>{item.Results}</span>
                                        </div>
                                    </div>
                                    <div className="coupon-item-odd">
                                        <span><FormattedMessage id="status" /></span>
                                        <div className="flex flex-row items-center">
                                            <span>{Redux.getCouponStatusName(item.status)}</span>
                                        </div>
                                    </div> 
                                    {
                                    item?.oddsInfo?.length > 0 && (
                                    <div className="coupon-item-odd">
                                        <span>{item.oddsInfo}</span>
                                    </div>                                         
                                    )
                                    }                                                 
                                </div>
                                
                                {
                                    (coupon?.result?.ticketType == "SK") && (
                                <div className="coupon-item-right">

                                        <div className={classNames({
                                            'coupon-item-banko': true,
                                            'selected': Boolean(item?.isBank == 1)
                                        })}>B</div>

                                </div>
                                    ) 
                                }                                
                            </div>
                        )
                    }}
                />
                )
                }

            </div>

            <UpDown mb="mb-0" upClick={() => {  }} downClick={() => {  }} />

            <div className="coupon-bottom">

                <div className="bottom-row-detail">
                    <span><FormattedMessage id="lang_coupon_no" defaultMessage="lang_coupon_no" /></span>
                    <span>{coupon?.result?.ticketId}</span>
                </div>

                <div className="bottom-row-detail">
                    <span><FormattedMessage id="lang_bet" defaultMessage="lang_bet" /></span>
                    <span>{coupon?.result?.totalStake}</span>
                </div>

                <div className="bottom-row-detail">
                    <span><FormattedMessage id="lang_coupon_date" defaultMessage="lang_coupon_date" /></span>
                    <span><CFormatDate date={coupon?.result?.ticketDate} /></span>
                </div>                

                <div className="bottom-row-detail">
                    <span><FormattedMessage id="lang_possible_gain" defaultMessage="lang_possible_gain" /></span>
                    <span>{coupon?.result?.maxPay}</span>
                </div>

                <div className="bottom-row-detail">
                    <span><FormattedMessage id="lang_bonus" defaultMessage="lang_bonus" /></span>
                    <span>{coupon?.result?.bonusPay}</span>
                </div>

                <div className="bottom-row-detail">
                    <span><FormattedMessage id="lang_total" defaultMessage="lang_total" /></span>
                    <span>{coupon?.result?.maxPay}</span>
                </div>

                <div className="bottom-row-detail">
                    <span><FormattedMessage id="lang_coupons" defaultMessage="lang_coupons" /></span>
                    <span>{coupon?.result?.totalTickets}</span>
                </div>

                <div className="bottom-row-detail">
                    <span><FormattedMessage id="lang_ratios" defaultMessage="lang_ratios" /></span>
                    <span>{coupon?.result?.totalTipps}</span>
                </div>

                <div className="bottom-row-detail">
                    <span><FormattedMessage id="lang_status" defaultMessage="lang_status" /></span>
                    <span><FormattedMessage id={Redux.getCouponStatusName(coupon?.result?.status)} /></span>
                </div>

                <div className="bottom-row-detail">
                    <span><FormattedMessage id="lang_payment" defaultMessage="lang_payment" /></span>
                    <span>{coupon?.result?.income}</span>
                </div>                

                <div className="bottom-row-detail">
                    <span><FormattedMessage id="lang_payment_status" defaultMessage="lang_payment_status" /></span>
                    <span><FormattedMessage id={coupon?.result?.paid == 1 ? "lang_paid" : "lang_waiting"} defaultMessage={coupon?.result?.paid == 1 ? "lang_paid" : "lang_waiting"} /></span>
                </div>                
                
            </div>

        </div>
        );
    }
}

const msp = (state) => {
  return {
      member: state.member,
      show_ticket_detail: state.show_ticket_detail
  }
}

export default connect(msp)(injectIntl(TicketDetail))
