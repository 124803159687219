import constants from "../utils/constants_epics";
import { store } from "../redux";
import avro from "avsc/etc/browser/avsc-types";
import avro_scheme from "../avro-scheme/match.json";
import { Buffer } from 'buffer';

export default class SocketUtil {
    constructor() {
        this.socket = null;
        this.counter = 0;
        this.interval = null;
        this.reconnectAttempts = 0;
        this.maxReconnectAttempts = 5;
        this.reconnectDelay = 5000; // 5 seconds
        this.listeners = new Map();
    }

    init() {
        this.connect();
    }

    on(eventName, callback) {
        if (!this.listeners.has(eventName)) {
            this.listeners.set(eventName, []);
        }
        this.listeners.get(eventName).push(callback);
    }

    off(eventName, callback) {
        if (!this.listeners.has(eventName)) return;
        const listeners = this.listeners.get(eventName);
        this.listeners.set(
            eventName,
            listeners.filter(listener => listener !== callback)
        );
    }

    emit(eventName, data) {
        if (!this.listeners.has(eventName)) return;
        this.listeners.get(eventName).forEach(callback => {
            try {
                callback(data);
            } catch (error) {
                console.error(`Error in ${eventName} event handler:`, error);
            }
        });
    }

    connect() {
        const socketUrl = `wss://ws.ibaterm${constants.base_url.replace(
            "https://api.ibaterm",
            ""
        )}/wsgo`;

        this.socket = new WebSocket(socketUrl);
        this.socket.binaryType = "arraybuffer";

        this.socket.onopen = (evt) => {
            console.log('WebSocket connection established');
            this.reconnectAttempts = 0; // Reset reconnect attempts on successful connection
            this.onOpen(evt);
            this.emit('open', evt);
        };

        this.socket.onclose = (evt) => {
            this.onClose(evt);
            this.emit('close', evt);
            this.handleReconnect();
        };

        this.socket.onmessage = (evt) => {
            this.onMessage(evt);
        };

        this.socket.onerror = (evt) => {
            console.error('WebSocket error:', evt);
            this.onError(evt);
            this.emit('error', evt);
        };
    }

    handleReconnect() {
        if (this.reconnectAttempts < this.maxReconnectAttempts) {
            console.log(`Attempting to reconnect... (Attempt ${this.reconnectAttempts + 1}/${this.maxReconnectAttempts})`);
            setTimeout(() => {
                this.reconnectAttempts++;
                this.connect();
            }, this.reconnectDelay);
        } else {
            console.error('Max reconnection attempts reached');
            this.emit('maxReconnectAttemptsReached');
        }
    }

    reset() {
        if (this.socket) {
            this.socket.close();
            this.connect();
        }
    }

    onOpen(evt) {
        this.timer();
    }

    onClose(evt) {
        this.clearTimer();
    }

    onMessage(evt) {
        const schema = avro.parse(avro_scheme);
        try {
            const match = schema.fromBuffer(Buffer.from(evt.data, 'binary'));
            this.processData(match);
            this.emit('data', match);
        } catch (error) {
            console.error('Error processing message:', error);
        }
    }

    onError(evt) {
        // Log error but let the onClose handler manage reconnection
        console.error('WebSocket error occurred:', evt);
    }

    disconnect() {
        if (this.socket) {
            this.socket.close();
            this.clearTimer();
            this.reconnectAttempts = this.maxReconnectAttempts; // Prevent auto-reconnect on manual disconnect
        }
    }

    processData(f) {
        try {
            let d = store.getState().live_matches;
            let e = d?.filter((x) => x.id == f?.matchid);
            if (e?.length > 0) {
                e = e[0];
                e.update(f);
            }
        } catch (error) {
            console.error('Error processing data:', error);
        }
    }

    timer() {
        this.counter = 0;
        if (this.interval) clearInterval(this.interval);
        this.interval = setInterval(() => {
            this.counter = this.counter + 1;
            // Reset connection every 10 minutes (600 seconds)
            if (this.counter >= 600) {
                console.log('Timer triggered connection reset');
                this.reset();
            }
        }, 1000);
    }

    clearTimer() {
        if (this.interval) {
            clearInterval(this.interval);
            this.interval = null;
        }
    }
}